import React, { useState, useEffect, useRef, useMemo } from 'react';
import { isEmpty, includes, set } from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DataGrid, {FilterRow, HeaderFilter, Export, Scrolling, Selection, Column, Editing, Popup, Position, Form, SearchPanel } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Button, Modal, Header, Image, Dropdown, Checkbox, Container } from 'semantic-ui-react';
import { GET_CATALOG_PRODUCTS_ID } from '../../models/catalogs/queries';
import {
	CREATE_CATALOG_PRODUCT,
	UPDATE_CATALOG_PRODUCT,
	DELETE_CATALOG_PRODUCT
} from '../../models/merchant/mutations';
import ProductGrid from './productGrid';
import EditProductValue from './editProductValue';
import CSVImportGrid from './CSVImportGrid';
import ImageSrcEdit from './imageSrcEdit';
import {withUser} from '../../context/user';
import './productsGrid.css';
import ListFeeds from '../Ingestor/list';
const uuidv1 = require('uuid/v1');



const DynalogProductsGrid = ({selectedSkus, ...props}) => {
	const [options, setOptions] = useState({});
	const [showImport, setShowImport] = useState(0);
	const [showExpired, setShowExpired] = useState(false);
	const [showDeleteFeed, setShowDeleteFeed] = useState(false);
	const [showDeleteDirty, setShowDeleteDirty] = useState(false);
	const [selected, setselected] = useState([]);
	const [refresh, setrefresh] = useState('');

	const {
		loading: loadingProducts,
		error: errorProducts,
		data,
		refetch
	} = useQuery(GET_CATALOG_PRODUCTS_ID, {
		variables: { id: parseInt(props.user.activeCatalog) }
	});
	const [createProduct] = useMutation(CREATE_CATALOG_PRODUCT);
	const [updateProduct] = useMutation(UPDATE_CATALOG_PRODUCT);
	const [deleteProduct] = useMutation(DELETE_CATALOG_PRODUCT);


	if (loadingProducts) return <p>Loading ...</p>;
	if (errorProducts) return <p>Error {JSON.stringify(errorProducts)}</p>;
	if (!data || !data.catalog) return <p>NO DATA</p>;
	const productsList = () => {
		console.log("show expired? ", showExpired)
		const products = data.catalog?.products || [];
			
		products.forEach(product=>{ 
			if (product.price && product.price>0)
			 product.price=product.price*1;
			if (product.salePrice && product.salePrice>0)
			 product.salePrice=product.salePrice*1;			 
		})
		if(showExpired)
			return products;
		
		return products.filter( (product)=> {
			return !product.expired
		}) ;
	};

	const refreshProducts = () => {
		refetch();
	}
	const pictureCellRender = (data) => {
		
		if (!isEmpty(data.value)) {
			const url = data.value.includes('http') ? data.value : `https://cdn.catalogs.com${data.value}`;
			if (props.isDrag) return <ProductGrid product={data} />;
			return <Image src={url} size="small" />;
		} else return <Image src={`https://cdn.catalogs.com/images/placeholder.png`} size="small" />
	};
	
	const selectedCellRender = (data) => {

		if (!isEmpty(data.value) && !isEmpty(selectedSkus)) {
			if (includes(selectedSkus, data.value))
				return <i className="big inverted teal check circle icon"></i>;
				
		}
	};
	const expiredCellRender = (data) => {
		if (data.value) {
				return <i className="big red dont icon"></i>;
				
		}
	};
	//INSERT
	const onInitNewRow = (event) => {
		//console.log('onInitNewRow:', event);
	};
	const onRowInserting = (event) => {
		const { oid, title, description, price, url, salePrice, src, category, sku, tags, expired, ...product } = event.data;
		
		const payload = {
			oid: uuidv1(),
			catalog: data.catalog.oid,
			title,
			description,
			price: (price ? price*1.00: 0.00),
			url,
			salePrice: (salePrice ? salePrice*1.00 : 0.00), 
			src,
			category,
			sku,
			tags,
			expired,
			dateEntered: new Date().toISOString().slice(0,19)
		};

		createProduct({
			variables: {
				product: payload
			}
		})
			.then((result) => {
				console.log(result);
			})
			.catch((e) => console.log(e));

		//console.log('onRowInserting:', payload, event);
	};
	const onRowInserted = (feed) => {
		refreshProducts()
		//console.log('onRowInserted:', feed);
	};

	// UPDATE
	const onEditingStart = (event) => {
		console.log('onEditingStart:', event);
	};
	const onRowUpdating = (event) => {
		//console.log('onRowUpdating:', event);
	};
	const onRowUpdated = (event) => {
		//console.log('onRowUpdated', event);
		const { oid, title, description, price, url, salePrice, src, category, brand, sku, color, tags, expired, ...product } = event.data;
		const payload = {
			title,
			description,
			price: (price ? price*1.00: 0.00),
			url,
			salePrice: (salePrice ? salePrice*1.00 : 0.00), 
			src,
			category,
			brand,
			sku,
			color,
			tags,
			expired
		};
		updateProduct({
			variables: {
				oid: oid,
				product: payload
			}
		})
			.then((result) => {
				console.log(result);
			})
			.catch((e) => console.log(e));
	};
	// DELETE
	const onRowRemoving = (event) => {
		const { oid } = event.data;

		console.log('onRowRemoving:', event.data);
		deleteProduct({
			variables: {
				oid: oid
			}
		})
			.then((result) => console.log(result))
			.catch((e) => console.log(e));
	};
	const onRowRemoved = (feed) => {
		console.log('onRowRemoved:', feed);
		refreshProducts();
	};
	const deleteDirtyProducts = () => {
		const allProducts = data?.catalog.products
		console.log("all products", allProducts);
		const dirtyProducts = allProducts.filter((p)=>!p.sku)
		console.log("dirty products", dirtyProducts);
		dirtyProducts.map((p)=>{
			deleteProduct({
				variables:{
					oid: p.oid
				}
			})
			.then((result)=> console.log(result))
			.catch((e) => console.log(e));
		})
		setShowDeleteFeed(false);
	}

	const deleteFeed = () => {
		const allProducts = data?.catalog.products;
		console.log("all products", allProducts);
		allProducts.map((p)=>{
			deleteProduct({
				variables:{
					oid: p.oid
				}
			})
			.then((result)=> console.log(result))
			.catch((e) => console.log(e));
		})
		setShowDeleteFeed(false);
	};

	const updateFieldMode = (key, value) => {
		const newOptions = { ...options, [key]: value };
		setOptions(newOptions);
	};
	const onValueChanged = (cell, value) => {
		cell.data.setValue(value);
	};
	const editNameField = (cell) => {
		const name = cell.data.item?.dataField;
		return (
			<EditProductValue
				name={name}
				defaultValue={cell.data.value}
				onValueChanged={(value) => onValueChanged(cell, value)}
				onUpdateMode={updateFieldMode}
				checked={options[name] ? true : false}
			/>
		);
	};
	const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
		setselected(selectedRowsData);
	}
	const createPage = (e,data) =>{
		console.log('sel',data);
		props.createPages(data.value,selected);
	}

	const templateOptions = [
		{ key: '2', text: '2 Products Per Page', value: '2' },
		{ key: '3', text: '3 Products Per Page', value: '3' },
		{ key: '4', text: '4 Products Per Page', value: '4' },
		{ key: '6', text: '6 Products Per Page', value: '6' },
		{ key: '8', text: '8 Products Per Page', value: '8' },
	];

	const toggleExpired = ()=> {
		setShowExpired(!showExpired)
	}

	const onEditorPreparing = (e) => {

		//e.editorOptions.disabled = e.parentType === "dataRow" && e.dataField === "sku" && !e.row.inserted;
		
	}
const toolbarCustom = (e) =>{
	e.toolbarOptions.items.unshift(
		{
		location: 'before',
		widget: 'dxButton',
		options: {
		  width: 136,
		  text: 'Show Expired',
		  onClick: toggleExpired
		}
	  }, {
		location: 'after',
		widget: 'dxButton',
		options: {
		  icon: 'refresh',
		  onClick: refreshProducts
		}
	  });
}

	return (
		<div className="products-grid">
			{props.import && (
				<>
				<Button primary onClick={() => setShowImport(1)}>
					Import CSV
				</Button>
				{/*<Button primary onClick={() => setShowImport(2)}>
					Pull Auto Feed
				</Button>*/}
				<Button secondary onClick={()=> setShowDeleteDirty(true)}>
					Delete dirty products
				</Button>
				<Button color={"red"} onClick={()=> setShowDeleteFeed(true)}>
					Delete feed
				</Button>
				</>
			)}

			{props.createPages &&	
					<Dropdown
					button
					className='icon'
					floating
					labeled
					icon='plus'
					options={templateOptions}
					search
					onChange ={createPage}
					text='Add Pages'/>
			}
			{
				<Modal closeIcon open={showImport!==0} onClose={() => setShowImport(0)}>
					<Header icon="browser" content="Import Feed" />
					<Modal.Content scrolling>
						{showImport===1 && 
						<CSVImportGrid catalog={data.catalog} onFinished={() => {
							setShowImport(0);
							refetch();
						}} />}
						{showImport===2 && 
					 	<ListFeeds user={props.user} catalog={data.catalog} onFinished={() => {
							setShowImport(0);
							refetch();
						}} />
						}
					</Modal.Content>
				</Modal>
			}
			{
				<Modal closeIcon open={showDeleteDirty} onClose={() => setShowDeleteDirty(false)}>
					<Header icon="browser" content="Delete Dirty Products" />
					<Modal.Content scrolling>
						<Container>
							<Header>This will delete all products with a missing SKU. Continue?</Header>
							<Button primary onClick={()=>deleteDirtyProducts()}>
								Yes
							</Button>
							<Button secondary onClick={()=>setShowDeleteDirty(false)}>
								No
							</Button>
						</Container>
					</Modal.Content>
				</Modal>
			}
			{
				<Modal closeIcon open={showDeleteFeed} onClose={() => setShowDeleteFeed(false)}>
					<Header icon="browser" content="Delete Feed" />
					<Modal.Content scrolling>
						<Container>
							<Header>This will delete all products, and may affect old versions. Continue?</Header>
							<Button primary onClick={()=>deleteFeed()}>
								Yes
							</Button>
							<Button secondary onClick={()=>setShowDeleteFeed(false)}>
								No
							</Button>
						</Container>
					</Modal.Content>
				</Modal>
			}


			<DataGrid
				columnHidingEnabled={true}
				style={{maxHeight:'500px'}}
				focusedRowEnabled={true}
				dataSource={productsList()}
				onSelectionChanged={onSelectionChanged}
				keyExpr={'oid'}
				showBorders={true}
				headerFilter={{visible: true}}
			//	columnAutoWidth={true}
				rowAlternationEnabled={true}
				columnHidingEnabled={true}
				onToolbarPreparing={toolbarCustom}
				onEditingStart={onEditingStart}
				onInitNewRow={onInitNewRow}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onEditorPreparing={onEditorPreparing}
			>
				 {props.createPages && <Selection mode="multiple" selectAllMode={'allPages'} showCheckBoxesMode={'onClick'}   /> }
				<Export fileName={data.catalog.name} enabled={true} allowExportSelectedData={true} />
				<Scrolling mode="virtual" />
				<Editing mode="popup" allowUpdating={true} allowAdding={true} allowDeleting={true}>
					<Popup title="Update Product" showTitle={true} width={700} height={525}>
						<Position my="center" at="center" of={window} />
					</Popup>
					<Form colCount={1} width="100%">
						<Item dataField="sku" />
						<Item dataField="title" />
						<Item dataField="price" />
						<Item dataField="salePrice" />
						<Item dataField="src" />
						<Item dataField="url" />
						<Item dataField="category" />
						<Item dataField="tags" />
						<Item dataField="description" />
						<Item dataField="expired" />
						<Item dataField="enteredDate" />
					</Form>
				</Editing>
				<SearchPanel visible={true} placeholder={'Search...'} />

				<Column
					dataField="src"
					caption="Image"
					width={150}
					allowSorting={false}
					cellRender={pictureCellRender}
					editCellComponent={ImageSrcEdit}
				/>
				<Column dataField="dateEntered" editCellComponent={editNameField} cssClass="productCell" />
				<Column dataField="sku" allowUpdating={false} cssClass="productCell" />
				{selectedSkus && <Column dataField="sku" caption="Selected" cellRender={selectedCellRender} width={50} cssClass="productCell" />}
				<Column dataField="title" editCellComponent={editNameField} cssClass="productCell" />
				<Column dataField="price" editCellComponent={editNameField} cssClass="productCell" />
				<Column dataField="salePrice" editCellComponent={editNameField} cssClass="productCell" />
				<Column dataField="category" editCellComponent={editNameField} cssClass="productCell" />
				<Column dataField="tags" editCellComponent={editNameField} cssClass="productCell" />
				<Column dataField="url" editCellComponent={editNameField} cssClass="productCell" />
				<Column dataField="description" editCellComponent={editNameField} cssClass="productCell" />
			</DataGrid>
		</div>
	);
};

export default withUser(DynalogProductsGrid);

import React, { useState, useLayoutEffect } from 'react';
import { isEmpty } from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DataGrid, {
	Paging,
	Column,
	Editing,
	Popup,
	Position,
	Form,
	SearchPanel,
	RowDragging
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Button, Card, Image, Icon } from 'semantic-ui-react';
import CategoryGrid from './categoryGrid';
import { GET_CATALOG_CATEGORIES_AND_PRODUCTS_ID } from '../../models/catalogs/queries';
import {
	CREATE_CATALOG_CATEGORY,
	UPDATE_CATALOG_CATEGORY,
	DELETE_CATALOG_CATEGORY
} from '../../models/merchant/mutations';
import ImageSrcEdit from './imageSrcEdit';
import { withUser } from '../../context/user';
const uuidv1 = require('uuid/v1');

const DynalogCategoriesGrid = (props) => {
	const [fcategories, setfcategories] = useState([]);

	const {
		loading: loadingDynalogCategories,
		error: dynalogCategoriesError,
		data
	} = useQuery(GET_CATALOG_CATEGORIES_AND_PRODUCTS_ID, {
		variables: { id: parseInt(props.user.activeCatalog) }
	});


	const [createCategory] = useMutation(CREATE_CATALOG_CATEGORY);
	const [updateCategory] = useMutation(UPDATE_CATALOG_CATEGORY);
	const [deleteCategory] = useMutation(DELETE_CATALOG_CATEGORY);

	useLayoutEffect(() => {
		if (!data || !data.catalog) 
			return;

		const categories = (props.activeOnly ? data.catalog.categories.filter((c)=>c.active) : data.catalog.categories) || [];
		const products = data.catalog.products || [];
		let flatcategories = [];
		categories.forEach(category => {
			let productsInCategory = products.filter(p=>{
				if (p.category == category.name) return p;
			})
			let lncat = { ...category, subcategories: null, productCount: productsInCategory.length};

			flatcategories.push(lncat);
			category.subcategories.forEach(sub => {
				flatcategories.push({ ...sub, parent: category.name });
			});
		});
		setfcategories(flatcategories);
		console.log('flattened cats', flatcategories)
	}, [data])

	if (loadingDynalogCategories) return <p>Loading ...</p>;
	if (dynalogCategoriesError) return <p>Error{JSON.stringify(dynalogCategoriesError)}</p>;
	if (!data || !data.catalog) return <p>NO DATA</p>;

	//INSERT
	const onInitNewRow = (event) => {
		//'onInitNewRow:', event);
	};
	const onRowInserting = (event) => {
		const { oid, name, ...category } = event.data;

		const payload = {
			oid: uuidv1(),
			name
		};

		createCategory({
			variables: {
				category: payload
			}
		})
			//.then((result) => console.log(result))
			.catch((e) => console.log(e));

		//'onRowInserting:', payload, event);
	};
	const onRowInserted = (feed) => {
		//'onRowInserted:', feed);
	};

	// UPDATE
	const onEditingStart = (event) => {
		//console.log('onRowUpdating:', event);
	};
	const onRowUpdating = (event) => {
		//console.log('onRowUpdating:', event);
	};
	const onRowUpdated = (event) => {
		//console.log('onRowUpdated', event);

		const { oid, name, srcStandard, active, ...category } = event.data;

		const payload = {
			name,
			srcStandard,
			active
		};
		updateCategory({
			variables: {
				oid: oid,
				category: payload
			}
		})
			//.then((result) => refetch())
			.catch((e) => console.log(e));
	};

	// DELETE
	const onRowRemoving = (event) => {
		const { oid } = event.data;

		//console.log('onRowRemoving:', event.data);

		deleteCategory({
			variables: {
				oid: oid
			}
		})
			//.then((result) => refetch())
			.catch((e) => console.log(e));
	};
	const onRowRemoved = (feed) => {
		//console.log('onRowRemoved:', feed);
	};




	const pictureCellRender = (data) => {

		if (!data.value)
			return <Icon size='small' name="image outline" />;
		const imageUrl = `https://cdn.catalogs.com${data.value}`
		return <Image src={imageUrl} size="mini" />;

	};

	const nameCellRender = (data) => {
		if (!isEmpty(data.value)) {
			if (props.isDrag) return <CategoryGrid category={data} />;
			return data.value;
		}
	};


	return (
		<div className="products-grid">
			<DataGrid
				columnHidingEnabled={true}
				style={{ maxHeight: '500px' }}
				focusedRowEnabled={true}
				dataSource={fcategories}
				keyExpr={'oid'}
				showBorders={true}
				onEditingStart={onEditingStart}
				onInitNewRow={onInitNewRow}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
			>
				<SearchPanel visible={true} placeholder={'Search...'} />
				<Editing mode="popup" allowUpdating={true} allowDeleting={true} allowAdding={false}>
					<Popup title="Category" showTitle={true} width={700} height={525}>
						<Position my="center" at="center" of={window} />
					</Popup>
					<Form colCount={1} width="100%">
						<Item dataField="name" />
						<Item dataField="image" />
						<Item dataField="active" />



					</Form>
				</Editing>
				<Column dataField="image" width={30} cellRender={pictureCellRender} editCellComponent={ImageSrcEdit} />
				<Column dataField="name" cellRender={nameCellRender} />
				<Column dataField="parent" />
				<Column dataField="productCount"/>
				<Column dataField="active" />
				{/*<Column dataField="id" width={200} />*/}
			</DataGrid>
		</div>
	);
};

export default withUser(DynalogCategoriesGrid);

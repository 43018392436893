import { gql } from "apollo-boost";

export const GET_CATALOG_ACCOUNTS = gql`
  query GetAccounts {
    accounts(condition: { active: true }, orderBy: NAME_ASC) {
      oid
      name
      email
      legacyId
      active
      logo
      catalogs: catalogsByAccount {
        oid
        id
        name
        title
        status
        cover
        active
        isDigital
        activeVersion: versionByActiveVersion {
          oid
          id
          name
          coverUrl
        }
        versions: versionsByCatalog(
          orderBy: VERSION_NO_DESC
          offset: 0
          first: 10
        ) {
          oid
          id
          name
          coverUrl
          versionNo
        }
      }
    }
  }
`;

export const GET_CATALOGS_LIST = gql`
  query GetCatalogsList {
    catalogs(orderBy: NAME_ASC) {
      oid
      id
      name
      title
      status
      cover
      active
      isDigital
      account: accountByAccount {
        oid
        name
        email
        legacyId
        active
        logo
      }
      activeVersion: versionByActiveVersion {
        oid
        id
        name
        coverUrl
      }
      versions: versionsByCatalog(
        orderBy: VERSION_NO_DESC
        offset: 0
        first: 10
      ) {
        oid
        id
        name
        coverUrl
        versionNo
      }
    }
  }
`;

export const GET_CATALOG = gql`
  query GetCatalog($oid: UUID!) {
    catalog(oid: $oid) {
      oid
      id
      name
      activeVersion: versionByActiveVersion {
        oid
        id
        coverUrl
      }
    }
  }
`;

export const GET_CATALOG_BY_VERSION = gql`
  query GetCatalogByVersion($id: Int!) {
    version: versionById(id: $id) {
      id
      oid
      name
      enableCategoryCatalogs
      contactUrl
      description
      options
      versionNo
      catalog: catalogByCatalog {
        id
        oid
        name
        title
        merchantId
        customSubdomain
        text2Disclaimer
        options
        description
        account: accountByAccount {
          oid
          logo
        }
      }
    }
  }
`;
export const GET_CATALOG_ID = gql`
  query Gets($id: Int!) {
    catalog: catalogById(id: $id) {
      oid
      id
      name
      cover
      title
      description
      account: accountByAccount {
        oid
        name
        email
        legacyId
        active
        logo
      }
      options
      activeVersion: versionByActiveVersion {
        oid
        id
        name
        coverUrl
      }
      versions: versionsByCatalog(orderBy: ID_DESC, offset: 0) {
        oid
        id
        name
        coverUrl
        versionNo
        description
        active
        options
      }
    }
  }
`;

export const GET_AUTHORIZED_USERS = gql`
  query GetauthorizedUsers {
    authorizedUsers {
      oid
      email
      accounts: authorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersConnection {
        totalCount
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($email: String!) {
    user: authorizedUserByEmail(email: $email) {
      oid
      email
      allAccounts: authorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsers {
        account: accountByPartnerAccess {
          oid
          legacyId
          name
          active
          catalogs: catalogsByAccount {
            id
            oid
            activeVersion: versionByActiveVersion {
              id
              oid
              name
              coverUrl
            }
            account
            merchantId
            description
            name
            active
            isDigital
            versions: versionsByCatalog(orderBy: ID_DESC) {
              id
              oid
              active
              description
              name
              coverUrl
            }
          }
        }
      }
    }
  }
`;
export const GET_VERSION_FULL_BY_ID = gql`
  query GetVersionFull($id: Int!) {
    version: versionById(id: $id) {
      id
      oid
      name
      active
      options
      order
      coverUrl
      products: productsByVersionsByVersionOid {
        sku
      }
      pages: pagesByVersion {
        oid
        imageSource
        imageUrl
        pageType
        options
      }
      catalog: catalogByCatalog {
        id
        oid
        activeVersion
        options
        account: accountByAccount {
          logo
          oid
        }
        products: productsByCatalog {
          oid
          sku
          title
          price
          url
          src
          category
          brand
          tags
          color
          expired
          dateEntered
        }
      }
    }
  }
`;

export const GET_CATALOG_VERSION_PRODUCTS = gql`
  query GetVersionProducts($id: Int!) {
    version: versionById(id: $id) {
      id
      oid
      name
      catalog: catalogByCatalog {
        id
        oid
        products: productsByCatalog {
          oid
          sku
          title
          price
          url
          src
          category
          brand
          tags
          color
          expired
          dateEntered
        }
      }
    }
  }
`;

export const GET_ACTIVE_VERSION = gql`
  query GetActive($id: Int!) {
    catalog: catalogById(id: $id) {
      id
      oid
      account
      name
      cover
      customSubdomain
      text2Disclaimer
      options
      activeVersion: versionByActiveVersion {
        id
        oid
        name
        coverUrl
        enableCategoryCatalogs
        contactUrl
        description
        options
        versionNo
      }
    }
  }
`;

export const GET_CATALOG_PRODUCTS1 = gql`
  query GetProductsCatalog($oid: UUID!) {
    catalog(oid: $oid) {
      id
      oid
      products: productsByCatalog {
        oid
        sku
        title
        price
        url
        src
        category
        brand
        tags
        color
        expired
        options
      }
    }
  }
`;

export const GET_CATALOG_PRODUCTS_ID = gql`
  query GetProductsCatalogId($id: Int!) {
    catalog: catalogById(id: $id) {
      id
      oid
      products: productsByCatalog {
        oid
        sku
        title
        description
        price
        salePrice
        url
        src
        category
        brand
        tags
        color
        expired
        options
        dateEntered
      }
    }
  }
`;
export const GET_CATALOG_CATEGORIES = gql`
  query GetCategories($oid: UUID!) {
    catalog(oid: $oid) {
      id
      oid
      categories: categoriesByCatalog {
        id
        oid
        name
        image
        options
        subcategories: subCategoriesByCategory {
          id
          oid
          name
          image
          options
        }
      }
    }
  }
`;

export const GET_CATALOG_CATEGORIES_ID = gql`
  query GetCatalogCategories($id: Int!) {
    catalog: catalogById(id: $id) {
      id
      oid
      categories: categoriesByCatalog {
        id
        oid
        name
        image
        active
        options
        subcategories: subCategoriesByCategory {
          id
          oid
          name
          active
          image
          options
        }
      }
    }
  }
`;

export const GET_CATALOG_CATEGORIES_AND_PRODUCTS_ID = gql`
  query GetCatalogCategoriesAndProducts($id: Int!) {
    catalog: catalogById(id: $id) {
      id
      oid
      categories: categoriesByCatalog {
        id
        oid
        name
        image
        active
        options
        subcategories: subCategoriesByCategory {
          id
          oid
          name
          active
          image
          options
        }
      }
      products: productsByCatalog {
        oid
        sku
        title
        description
        price
        salePrice
        url
        src
        category
        brand
        tags
        color
        expired
        options
      }
    }
  }
`;

export const GET_CATALOG_VERSION_CATEGORIES = gql`
  query GetVersionVersionCategories($id: Int!) {
    version: versionById(id: $id) {
      id
      oid
      name
      catalog: catalogByCatalog {
        id
        oid
        categories: categoriesByCatalog {
          id
          oid
          name
          image
          subcategories: subCategoriesByCategory {
            id
            oid
            name
            image
          }
        }
      }
    }
  }
`;

export const GET_LEADS_BY_ACCOUNT = gql`
  query GetLeadsAccount($account: UUID!) {
    account(oid: $account) {
      name
      oid
      catalogsByAccount {
        title
        active
        oid
        name
        subscriptionsByCatalog(orderBy: DATE_DESC) {
          oid
          date
          exportStatus
          profileByCustomer {
            firstName
            lastName
            email
            phone
            street
            city
            state
            zipPostal
          }
        }
      }
    }
  } 
`;

export const GET_ACCOUNT_FULL = gql`
query GetAccountDetails($oid: UUID!) {
  account(oid: $oid) {
    oid
    active
    email
    name
    legacyId
    catalogs: catalogsByAccount {
      account
      active
      description
      id
      isDigital
      merchantId
      name
      oid
      activeVersion
      versions: versionsByCatalog {
        active
        coverUrl
        description
        id
        name
        oid
      }
    }
  }
}
`;
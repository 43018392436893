import React, { useEffect, useRef, useCallback, useState } from 'react';

import { isEmpty } from 'lodash';
import { Icon, Button } from 'semantic-ui-react';
import { useDrop } from 'react-dnd';
import { fabric } from 'fabric';
import { ColorBox } from 'devextreme-react';
import CanvasSelectorMenu from './CanvasSelectorMenu';
import CanvasDesignerMenu from './CanvasDesignerMenu';

import './CanvasEditor.css';
import annotation from './pluscircle.svg';
import CanvasTextProperties from './CanvasTextProperties';
const uuidv1 = require('uuid/v1');

const CanvasEditor = (props) => {
	const page = props.page;
    const canvasRef = useRef(null);
    const [initialCanvas, setInitialCanvas] = useState(null);
	const [selectedObject, setSelectedObject] = useState({});
	const [backgroundColor, setBackgroundColor] = useState('#CCC');
    const [ selectedProducts, setSelectedProducts ] = useState([]);


	const [{ canDrop, isOver }, drop] = useDrop({
		accept: ['Product', 'Category'],
		drop: (_item, monitor) => {
			switch (_item.type) {
				case 'Product':
					addProduct2Canvas(_item);
					break;
				case 'Category':
					addCategory2Canvas(_item);
					break;
				default:
				// code block
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});
	const isActive = canDrop && isOver;

	useEffect(() => {
		setSelectedObject({});
		var fabricCanvas ={};
		if (canvasRef.current)
		{
			canvasRef.current.clear();
			canvasRef.current.dispose();
			canvasRef.current = null;

		}	

		 fabricCanvas = new fabric.Canvas(page.oid, {
			preserveObjectStacking: true,
			width: props.width || 400,
			height: props.height || 600,
			selection: true,
			selectionBorderColor: 'green',
			backgroundColor: backgroundColor
		});

		if (!isEmpty(props.page.options) && !isEmpty(props.page.options.canvas)) {
			setBackgroundColor(props.page.options.canvas.data.background);
		}

		fabricCanvas.on('mouse:down', showPropertiesPanel);
		fabricCanvas.on('selection:created', showPropertiesPanel);
		//fabricCanvas.on('selection:updated', showPropertiesPanel);
		//fabricCanvas.on('selection:cleared', showPropertiesPanel);

		const backgroundImage = props.background;
		if (!isEmpty(backgroundImage)) {
			fabric.Image.fromURL(backgroundImage, function (img) {
				fabricCanvas.setBackgroundImage(backgroundImage, fabricCanvas.renderAll.bind(fabricCanvas), {
					scaleX: fabricCanvas.width / img.width,
					scaleY: fabricCanvas.height / img.height
				});
			});
		}
		// if page has custom objects load it
		if (!isEmpty(props.page.options) && !isEmpty(props.page.options.canvas)) {
			try {
				fabricCanvas.loadFromJSON(props.page.options.canvas.data, function () {
					// making sure to render canvas at the end
					fabricCanvas.renderAll();
				});
			} catch (error) {
				console.log(error);
			}
		} else if (props.page.pageType === 'pdf') {

		props.page?.options?.products &&
			props.page.options.products.forEach(element => {

				fabric.Image.fromURL(annotation, (image) => {
					image.id = uuidv1();
					image.set({
						left: 350 * element.x / 100,
						top: 500 * element.y / 100
					});
					image.type = 'image';
					image.linkoutOptions = 'product';
					image.linkout = element.sku;
					image.showAs = 'annotation';
					image.src = '';
					image.visibility = '0';
					fabricCanvas.add(image).setActiveObject(image);
					image.scaleToHeight(15);
					image.scaleToWidth(15);
				});
			});
		}
		console.log('fabricCanvas', fabricCanvas)
        canvasRef.current = fabricCanvas;
        setInitialCanvas(canvasRef.current);
        //return () => (canvasRef.current = null);
	}, [props.background, props.page.oid]);

    useEffect(() =>{
        if (props.pageSaved == true)
            savePage();
    }, [props.pageSaved])

	const showPropertiesPanel = (options) => {
		const object = options.target;
		if (object) {
			setSelectedObject(object);
		} else {
			setSelectedObject({});
		}
	};

	const addCategory2Canvas = ({ category }) => {
		const data = category.data;

		const productImage = data.srcStandard ?
			data.srcStandard.includes('http') ? data.srcStandard : `https://cdn.catalogs.com${data.srcStandard}`
			: 'https://cdn.catalogs.com/small/images/placeholder.png';
		const square = new fabric.Rect({
			id: uuidv1(),
			type: 'rect',
			width: 200,
			height: 200,
			stroke: '#fff',
			strokeWidth: 0,
			fill: '#000',
			padding: 0,
			opacity: 1
		});

		square.visibility = '0';
		square.src = productImage;
		square.linkoutOptions = 'category';
		square.showAs = 'square';
		square.linkout = data.id;
		canvasRef.current.discardActiveObject();
		canvasRef.current.add(square).setActiveObject(square);
		square.center();
	};

	const addProduct2Canvas = ({ product }) => {
		const data = product.data;

		const productImage = data.src.includes('http') ? data.src : `https://cdn.catalogs.com${data.src}`;
		fabric.Image.fromURL(productImage, function (image) {
			image.id = uuidv1();
			image.type = 'image';
			image.src = productImage;
			image.linkoutOptions = 'product';
			image.showAs = 'image';
			image.visibility = '0';
			image.linkout = data.sku;
			canvasRef.current.discardActiveObject();
			canvasRef.current.add(image).setActiveObject(image);
			image.scaleToHeight(150);
			image.scaleToWidth(150);
			image.center();
		});

		addSku(data.sku)

	};

	const addCircle = () => {
		const uuidv1 = require('uuid/v1');
		const circle = new fabric.Circle({
			id: uuidv1(),
			type: 'circle',
			radius: 10,
			stroke: '#fff',
			strokeWidth: 3,
			fill: '#000'
		});
		canvasRef.current.discardActiveObject();
		canvasRef.current.add(circle).setActiveObject(circle);
		circle.center();
	};

	const addSquare = () => {
		const uuidv1 = require('uuid/v1');
		const square = new fabric.Rect({
			id: uuidv1(),
			type: 'rect',
			width: 100,
			height: 100,
			stroke: '#fff',
			strokeWidth: 3,
			fill: '#000',
			padding: 10
		});
		canvasRef.current.discardActiveObject();
		canvasRef.current.add(square).setActiveObject(square);
		square.center();
	};

	const addText = () => {
		const uuidv1 = require('uuid/v1');
		const text = new fabric.IText('TEXT', {
			id: uuidv1(),
			editable: true,
			hasBorders: true,
			hasControls: true,
			width: 100,
			fontSize: 20,
			textAlign: 'left',
    		lockRotation: true
		});
		text.editable = true;
		canvasRef.current.discardActiveObject();
		canvasRef.current.add(text).setActiveObject(text);
		text.center();
	};

	const addImage = () => {
		const uuidv1 = require('uuid/v1');
		fabric.Image.fromURL('https://cdn.catalogs.com/small/images/placeholder.png', function (image) {
			image.id = uuidv1();
            image.type = 'image';
			canvasRef.current.discardActiveObject();
			canvasRef.current.add(image).setActiveObject(image);
			image.center();
		});
	};

	const addFullPage = () => {
		console.log("adding full page");
		const uuidv1 = require('uuid/v1');
		const fullPage = new fabric.Rect({
			id: uuidv1(),
			type: 'rect',
			width: props.width || 400,
			height: props.height || 700,
			fill: '#000',
			padding: 10,
			opacity: 0.2
		});
		canvasRef.current.discardActiveObject();
		canvasRef.current.add(fullPage).setActiveObject(fullPage);
		canvasRef.current.getActiveObject().set('visibility', '3'); // Hidden
		fullPage.center();
	}

	const updateObject = (data) => {

		const activeObject = canvasRef.current.getActiveObject();
		const objectType = activeObject.get('type');
		if (data.fontFamily) {
			activeObject.set('fontFamily', data.fontFamily);
		}
		if (data.fontSize) {
			activeObject.set('fontSize', data.fontSize);
		}
		if (data.stroke) {
			activeObject.set('stroke', data.stroke);
		}
		if (data.strokeWidth) {
			activeObject.set('strokeWidth', data.strokeWidth);
		}
		if (data.fill) {
			activeObject.set('fill', data.fill);
		}
		if (objectType == 'image' && data.imageUrl) {
			activeObject.setSrc(data.imageUrl, (image) => {
				setSelectedObject(canvasRef.current.getActiveObject());
				canvasRef.current.renderAll();
            });
		}
		if (data.linkoutOptions) {
			activeObject.set('linkoutOptions', data.linkoutOptions);
		}
		if (data.ShowAs) {
			activeObject.set('showAs', data.ShowAs);
		}
		if (data.linkout) {
			activeObject.set('linkout', data.linkout);
		}
		if (data.hasOwnProperty('opacity')) { // change from just 'if (data.opacity)' because it was not accepting when new opacity was 0 
			activeObject.set('opacity', data.opacity);
		}
		if (data.visibility) {
			activeObject.set('visibility', data.visibility);
		}
		setSelectedObject({
			...canvasRef.current.getActiveObject(),
			data
		});
		canvasRef.current.renderAll();
	};

	const addElement = (type) => {
		switch (type) {
			case 'circle':
				addCircle();
				break;
			case 'square':
				addSquare();
				break;
			case 'text':
				addText();
				break;
			case 'image':
				addImage();
				break;
			case 'fullPage':
				addFullPage();
				break;
			default:
			// code block
		}
		//setSelectedObject(canvasRef.current.getActiveObject());
	};

	const deleteElement = () => {
		const element = canvasRef.current.getActiveObject();
		console.log("delete element ", element)
		canvasRef.current.remove(element);
		canvasRef.current.renderAll();
		setSelectedObject({});


		if(element.linkoutOptions==="product"){
		//	TODO: Update Product List 
			const selected = selectedProducts.filter( (sku) => {
				return sku !== element.linkout
			})
			setSelectedProducts(selected);
		}
	};

	const updatePageBg = (color) => {
		canvasRef.current.setBackgroundColor(color);
		canvasRef.current.renderAll();
		setBackgroundColor(color);
	};

	const savePage = () => {
        // save json
		canvasRef.current.renderAll();
		const width = canvasRef.current.getWidth();
		const height = canvasRef.current.getHeight();
		const json = canvasRef.current.toJSON('name', 'size');
        props.savePageJson(width, height, json);
        props.resetPageSaved();
        if (props.confirmChangesOpen){  // If saving with Confirm Changes modal, close after saving
            props.resetConfirmChanges();
            props.closePageEditor();
        }
        props.setUnsavedChanges(false);
        setSelectedObject({}); // 
		// pass json to parent to call mutation or pass pageId to this component
	};
	const convertProduct = async (type) => {
		const current = canvasRef.current.getActiveObject();

		if (current.src === undefined && current.type === 'image')
			current.src = current.getSrc();


		let image = {};

		switch (type) {
			case 'image':
			case 'product':
				fabric.Image.fromURL(current.src, (image) => {
					image.id = uuidv1();
					image.type = 'image';
					image.linkoutOptions = current.linkoutOptions;
					image.linkout = current.linkout;
					image.showAs = type;
					image.visibility = current.visibility;
					image.src = current.src;
					canvasRef.current.remove(current);
					canvasRef.current.discardActiveObject();
					canvasRef.current.add(image).setActiveObject(image);
					image.scaleToHeight(150);
					image.scaleToWidth(150);
					image.center();
				});
				break;
			case 'annotation':
				fabric.Image.fromURL(annotation, (image) => {
					image.id = uuidv1();
					image.type = 'image';
					image.linkoutOptions = current.linkoutOptions;
					image.linkout = current.linkout;
					image.showAs = type;
					image.src = current.src;
					image.visibility = current.visibility;
					canvasRef.current.remove(current);
					canvasRef.current.discardActiveObject();
					canvasRef.current.add(image).setActiveObject(image);
					image.scaleToHeight(15);
					image.scaleToWidth(15);
					image.center();
				});
				break;
			case 'square':
				image = new fabric.Rect({
					width: 200,
					height: 200,
					stroke: '#fff',
					strokeWidth: 0,
					fill: '#000',
					padding: 0,
					opacity: 1
				});
				image.linkoutOptions = current.linkoutOptions;
				image.visibility = current.visibility;
				image.linkout = current.linkout;
				image.showAs = type;
				image.src = current.src;
				canvasRef.current.remove(current);
				canvasRef.current.discardActiveObject();
				canvasRef.current.add(image).setActiveObject(image);
				break;
			case 'fullPage': 
				console.log("converting to fullPage");
				image = new fabric.Rect({
					width: props.width || 200,
					height: props.height || 200,
					padding: 0,
					opacity: 0.1
				});
				image.linkoutOptions = current.linkoutOptions;
				image.visibility = '3';
				image.linkout = current.linkout;
				image.showAs = type;
				image.src = current.src;
				canvasRef.current.remove(current);
				canvasRef.current.discardActiveObject();
				canvasRef.current.add(image).setActiveObject(image);
				break;
			default:
				return;
		}

	}

	const moveElementIndex = (v) => {
		const canvas = canvasRef.current;
		const element = canvas.getActiveObject();
		switch (v) {
			case 'up':
				canvas.bringForward(element);
				break;
			case 'down':
				canvas.sendToBack(element);
				break;
			default:
			// code block
		}
	};

	const updateTextProperty = (v) => {
		const activeObject = canvasRef.current.getActiveObject();
		switch (v) {
			case 'alignLeft':
				activeObject.textAlign = 'left';
				break;
			case 'alignCenter':
				activeObject.textAlign = 'center';
				break;
			case 'alignJustify':
				activeObject.textAlign = 'justify';
				break;
			case 'alignRight':
				activeObject.textAlign = 'right';
				break;
			case 'bold':
				break;
			default:
			// code block
		}
		canvasRef.current.renderAll();
	};
	const addSku = (sku)=> {
		setSelectedProducts([...selectedProducts, sku])
	}


	return (
		<div className="Editor">
			<CanvasSelectorMenu
				onAddElement={addElement}
				onDeleteObject={deleteElement}
				selected={selectedObject}
				onItemIndex={(v) => moveElementIndex(v)} />
			<div className="canvasWrapper" ref={drop}>
				{!isEmpty(selectedObject) && selectedObject.type == 'i-text' && <CanvasTextProperties onClick={updateTextProperty} />}
				<div>
					<ColorBox value={backgroundColor} onValueChanged={({ value }) => updatePageBg(value)} />
				</div>
				<canvas id={page.oid} />
			</div>
			{!isEmpty(selectedObject) && (
				<>
                {props.setUnsavedChanges(true)} 
				<CanvasDesignerMenu
					onUpdateObject={updateObject}
					convertProduct={convertProduct}
					initialValues={selectedObject}
					onItemIndex={(v) => moveElementIndex(v)}
				/>
				</>        
			)}
		</div>
	);
};

const extraProperties = ['linkout', 'linkoutOptions', 'showAs', 'visibility', 'src'];
fabric.Object.prototype.toObject = (function (toObject) {
	return function (propertiesToInclude) {
		propertiesToInclude = (propertiesToInclude || []).concat(extraProperties);
		return toObject.apply(this, [propertiesToInclude]);
	};
})(fabric.Object.prototype.toObject);

export default CanvasEditor;

import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { myConfig } from '../../config';
import axios from 'axios';
import { isEmpty } from 'lodash';
import DropZone from '../Dynalog/CreateForm/dropZone';
import { Button, Form, Input, Dropdown, Checkbox, TextArea } from 'semantic-ui-react';
import { RHFInput } from 'react-hook-form-input';

const DynalogSettingsForm = ({ catalog, version, ...props }) => {
	const catalogOptions = catalog.options || {};;
	console.log('catalogOptions', catalogOptions)

	const initialLogo = catalogOptions.logo || catalog.account.logo;

	const leadModes = [
		{
			value: 0,
			text: 'None (Default)'
		},
		{
			value: 1,
			text: 'All products'
		},
		{
			value: 2,
			text: "Missing Product URL's"
		},
		{
			value: 3,
			text: "All Link-Outs & Products"
		}
	];
	const leadFormOptions = [
		/*
		{
			value: 0,
			text: 'Short Form'
		},
		*/
		{
			value: 1,
			text: 'Lead Form'
		},
		{
			value: 2,
			text: 'Call Form'
		}
	];
	const priceOptions = [
		{
			value: 0,
			text: 'Hide'
		},
		{
			value: 1,
			text: 'Show Normal (Default)'
		},
		/*
		{
			value: 2,
			text: 'If Price empty then use Sale Price'
		}	
		*/
		{
			value: 3,
			text: 'Commas'
		}
	]
	const emailMsgDefault = `Dear {lead.name},
	
Thank you for requesting information about {catalog.title}. 

Please follow the link below to view your catalog.

{catalogUrl}

Best Regards,
{account.name}`;


	const txtMsgDefault = `Thank you for requesting information about {catalog.title}. Please follow the link below to view your catalog`;


	const [logo, setLogo] = useState(initialLogo);
	const { register, errors, handleSubmit, setValue, control } = useForm();


	const onDrop = useCallback((acceptedFiles) => {
		if (!isEmpty(acceptedFiles)) {
			acceptedFiles.forEach(async (file) => {
				const fileType = file.type;
				await handleFile(file, 'single', `/${file.name}`);
			});
		}
	}, []);
	const handleFile = (file, action, name = '') => {
		const reader = new FileReader();
		reader.onload = async () => {
			const fileAsBinaryString = reader.result;
			await axios({
				method: 'post',
				url: `${myConfig.IMAGE_API_URL}/${action}/logo_${catalog.id}/${file.name}`,
				data: fileAsBinaryString
			}).then(function (response) {
				const image = response.data;
				console.log(image);
				setLogo(image.path);
				props.onUpdateLogo(image.path);
			})
				.catch(function (error) {
					console.log(error);
				});
		};
		reader.onabort = () => console.log('file reading was aborted');
		reader.onerror = () => console.log('file reading has failed');

		reader.readAsArrayBuffer(file);
	};

	const vsettings = () => {
		return (<>
			<Form.Group widths="equal">
				<Form.Field>
					<Controller
						as={<Checkbox label="Force Links to Open in New Window" />}
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="forceNewWindowLinkout"
						defaultChecked={catalogOptions.forceNewWindowLinkout}
					/>
				</Form.Field>
				<Form.Field>
					<Controller
						as={<Checkbox label="Disable Categories" />}
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="disableCategories"
						defaultChecked={catalogOptions.disableCategories}
					/>
				</Form.Field>
				<Form.Field>
					<Controller
						as={<Checkbox label="Hide Other Catalogs" />}
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="disableOtherCatalogs"
						defaultChecked={catalogOptions.disableOtherCatalogs}
					/>
				</Form.Field>
				<Form.Field>
					<Controller
						as={<Checkbox label="Hide Page Number" />}
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="hidePageNumber"
						defaultChecked={catalogOptions.hidePageNumber}
					/>
				</Form.Field>
				<Form.Field>
					<Controller
						as={<Checkbox label="Use PDF Page Size" />} //Controls if pages will re-size based on uploaded image dimensions
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="usePDFPageSize"
						defaultChecked={catalogOptions.usePDFPageSize}
					/>
				</Form.Field>
			</Form.Group>
			<Form.Group widths={4}>
			<Form.Field>
					<label>Custom Group URL Title</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={catalogOptions.groupUrlTitle}
						setValue={setValue}
						name="groupUrlTitle"
					/>
				</Form.Field>
				<Form.Field>
					<label>Custom Category  Label</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={catalogOptions.categoryBarLabel}
						setValue={setValue}
						name="categoryBarLabel"
					/>
				</Form.Field>
				<Form.Field>
					<label>Universal Linkout</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={catalogOptions.universalLinkout}
						setValue={setValue}
						name="universalLinkout"
					/>
				</Form.Field>
				<Form.Field>
					<label>Price Options</label>
					<Controller
						as={<Dropdown fluid selection options={priceOptions} />}
						defaultValue={catalogOptions.priceOption}
						control={control}
						name="priceOption"
						onChange={([e, data]) => {
							return data.value;
						}}
					/>
				</Form.Field>
			</Form.Group>
		</>)
	}

	const leadsettings = () =>
		<>
			<Form.Group widths="equal">
				<Form.Field>
					<label>Show Lead From: </label>
					<Controller
						as={<Dropdown fluid selection options={leadModes} />}
						defaultValue={catalogOptions.leadOptions}
						control={control}
						name="leadOptions"
						onChange={([e, data]) => {
							return data.value;
						}}
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Form Type: </label>
					<Controller
						as={<Dropdown fluid selection options={leadFormOptions} />}
						defaultValue={catalogOptions.leadFormType}
						control={control}
						name="leadFormType"
						onChange={([e, data]) => {
							return data.value;
						}}
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Form Email</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={catalogOptions.leadEmail}
						setValue={setValue}
						name="leadEmail"
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Form Phone</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={catalogOptions.leadPhone}
						setValue={setValue}
						name="leadPhone"
					/>
				</Form.Field>
			</Form.Group>
			<Form.Group widths={2}>
				<Form.Field>
					<label>Lead Form Message</label>
					<RHFInput
						as={<TextArea />}
						register={register}
						defaultValue={catalogOptions.leadHeader}
						setValue={setValue}
						name="leadHeader"
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Form Comments</label>
					<RHFInput
						as={<TextArea />}
						register={register}
						defaultValue={catalogOptions.leadComments}
						setValue={setValue}
						name="leadComments"
					/>
				</Form.Field>

			</Form.Group>
		</>

	const logosettings = () =>
		<>
			<Form.Group widths={2}>
				<Form.Field>
					<label>Logo</label>
					<DropZone
						image={<img
							src={`https://cdn.catalogs.com${logo}`}
							alt="logo"
							style={{
								width: '100px'
							}}
						/>}
						onDrop={onDrop}
						accept={'image/*'}
						multiple={false}
						maxSize={5000000}
						title="Drag image"
					/>
				</Form.Field>
				<Form.Group widths="equal">
					<Form.Field>
						<Controller
							as={<Checkbox label="Hide Logo" />}
							control={control}
							onChange={([e, data]) => {
								return data.checked;
							}}
							name="hideLogo"
							defaultChecked={catalogOptions.hideLogo}
						/>
					</Form.Field>
					<Form.Field>
						<Controller
							as={<Checkbox label="Hide Left Bar" />}
							control={control}
							onChange={([e, data]) => {
								return data.checked;
							}}
							name="hideLeftBar"
							defaultChecked={catalogOptions.hideLeftBar}
						/>
					</Form.Field>
				</Form.Group>
			</Form.Group>
		</>;

	const googleSettings = () => <>
		<Form.Group widths="equal">
			<Form.Field>
				<label>Google Tracking UA</label>
				<RHFInput
					as={<Input />}
					register={register}
					defaultValue={catalogOptions.googleTrackingUa}
					setValue={setValue}
					name="googleTrackingUa"
				/>
			</Form.Field>
			<Form.Field>
				<label>Google Utm Campaign</label>
				<RHFInput
					as={<Input />}
					register={register}
					defaultValue={catalogOptions.googleUtmCampaign}
					setValue={setValue}
					name="googleUtmCampaign"
				/>
			</Form.Field>
			<Form.Field>
				<label>Google Utm Medium</label>
				<RHFInput
					as={<Input />}
					register={register}
					defaultValue={catalogOptions.googleUtmMedium}
					setValue={setValue}
					name="googleUtmMedium"
				/>
			</Form.Field>
			<Form.Field>
				<label>Google Utm Source</label>
				<RHFInput
					as={<Input />}
					register={register}
					defaultValue={catalogOptions.googleUtmSource}
					setValue={setValue}
					name="googleUtmSource"
				/>
			</Form.Field>
		</Form.Group></>;

	const templatesettings = () =>
		<Form.Group widths="equal">
			<Form.Field>
				<label>Email Message</label>
				<RHFInput
					as={<TextArea />}
					register={register}
					rows={5}
					//defaultValue={catalogOptions.emailMsg === undefined ? emailMsgDefault : catalogOptions.emailMsg}
					defaultValue={catalogOptions.emailMsg || emailMsgDefault}

					setValue={setValue}
					name="emailMsg"
				/>

			</Form.Field>
			<Form.Field>
				<label>Text Message</label>
				<RHFInput
					as={<TextArea rows={2} />}
					register={register}
					defaultValue={catalogOptions.txtMsg || txtMsgDefault}
					setValue={setValue}
					name="txtMsg"
				/>
			</Form.Field>
		</Form.Group>;


	return (
		<Form onSubmit={handleSubmit(props.onSubmit)}>
			<Button primary type="submit">
				SAVE
			</Button>
			<Form.Group widths="equal">
				<Form.Field>
					<label>Title</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={catalog.title || catalog.name}
						setValue={setValue}
						name="title"
						rules={{ required: true, maxlength: 20 }}
					/>
					{errors.title && 'Title is required'}
				</Form.Field>
				<Form.Field>
					<label>Description</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={catalog.description}
						setValue={setValue}
						name="description"
					/>
				</Form.Field>
			</Form.Group>
			<div>This information will be Used for Page Title / SEO Purposes</div>
			<div className="ui divider"> </div>
			{logosettings()}
			<div className="ui divider"></div>
			{vsettings()}
			<div className="ui divider"></div>
			{leadsettings()}
			<div className="ui divider"></div>
			{googleSettings()}
			<div className="ui divider"></div>
			{templatesettings()}
			<div className="ui divider"></div>
		</Form>
	);
};

export default DynalogSettingsForm;
